import { TripsService 		} from 'src/app/services/trips/trips.service';
import { FirebaseService 	} from 'src/app/services/firebase/firebase.service';
import { LocatorService 	} from './services/locator/locator.service';
import { ChatService 		} from 'src/app/services/chat/chat.service';
import { Component, 
		 OnInit, 
		 ViewChild 			} from '@angular/core';
import { MenuController, 
		 NavController, 		
		 Platform			} from '@ionic/angular';
import { SplashScreen 		} from '@ionic-native/splash-screen/ngx';
import { StatusBar 			} from '@ionic-native/status-bar/ngx';
import { CommonsService 	} from './services/commons/commons.service';
import { AngularFireAuth 	} from '@angular/fire/auth';
import { Storage 			} from '@ionic/storage';

@Component({
  selector		: 'app-root',
  templateUrl	: 'app.component.html',
  styleUrls		: ['app.component.scss']
})

export class AppComponent implements OnInit {

	rootPage	: any = "";
	userInfo 	: any = {};
	pages    	: any[];
  
	constructor(
	  public  platform     	: Platform,
	  public  statusBar    	: StatusBar,
	  public  splashScreen 	: SplashScreen,
	  private commons      	: CommonsService,
	  private storage      	: Storage,
	  public  menuCtrl     	: MenuController,
	  public  chat         	: ChatService,
	  private locator 		: LocatorService,
	  private userCtrl		: FirebaseService,
	  private tripCtrl		: TripsService,
	  private afAuth        : AngularFireAuth,
	  private navCtrl		: NavController
	) {
		this.initApp();
		this.platform.ready().then(() => {
			//this.statusBar.styleDefault();
			this.statusBar.backgroundColorByHexString("#303030");
			this.statusBar.show();				
			this.splashScreen.hide();
			//let splash = modalCtrl.create(SplashPage);
			//splash.present();		
			menuCtrl.enable(false, 'right');
		});
	}

	async ngOnInit()		{ 	
		await this.initializeApp(); 
		await this.initApp();	
	}
  
	async initializeApp() 	{	
		try{
			console.group("initializeApp");
			this.commons.simulation 	= true;
			this.commons.initTranslate('en');	
			await this.initUser();
			this.initMenu();
			console.groupEnd();
		}catch(e){
			await Promise.resolve(this.storage.set('user',{}));
			this.rootPage = 'login';
		}
	}
	
	async loginWatchdog(auth){	
		if(auth){	
			let firebaseUser	=	await this.userCtrl.getUserById(auth.uid);
			this.userInfo		=	firebaseUser.data();
			console.log('has data and user info is:', this.userInfo);
			if(this.userInfo){
				await this.initCustomer();
				if(this.rootPage == 'login'){   this.menuCtrl.enable(false);				  }
				this.navCtrl.navigateRoot(this.rootPage);
			}else{
				return; //is anonimously and we don't have registered in our users collection
			}
		} else {	
			await this.initLocalStorageCustomer();
			await this.initCustomer();
		}
		await Promise.resolve(this.storage.set('user', this.userInfo)); //update at the storage the latest updated customer info
		this.initMenu();
	}

	/**
	 * Method which call the firebase authState, 
	 * which represents the storage from firebase users. 
	 * As is a subscription, when user logged out, this method
	 * automatically is trigged and remove all the persistent states, 
	 * overriding possibles race conditions and unstable states.
	 */
	async initApp()			{	
		this.commons.simulation 	= true;
		this.commons.initTranslate('en');	
		try{
			this.afAuth.authState.subscribe(async auth => this.loginWatchdog(auth));
		}catch(e){
			console.log('error on try catch init');
			await Promise.resolve(this.storage.set('user',{}));
			this.rootPage = 'login';
			this.menuCtrl.enable(false);
		}	
	}

	async initUser()		{
		this.userInfo					=	await Promise.resolve(this.storage.get('user')) || {};
		this.afAuth.authState.subscribe((auth) => {
			  console.log('FIREBASE AUTH STATE',auth)
		});
		  
		this.userInfo.control 			= 	(Object.keys(this.userInfo).length === 0 && this.userInfo.constructor === Object) ? {} : this.userInfo.control;

		this.userInfo.control.isLogged	=	this.userInfo.control.isLogged		!= undefined	?	this.userInfo.control.isLogged		: false;
		this.userInfo.control.hasCurrent=	this.userInfo.control.hasCurrent	!= undefined 	?	this.userInfo.control.hasCurrent	: false;
		this.userInfo.control.status	=	this.userInfo.control.status		!= undefined	?	this.userInfo.control.status		: 'NOT_LOGGED';

		await this.checkSteps();

		this.rootPage	=	this.commons.getStatusPage({info : this.userInfo}); //SHOULD DO METHOD TO CHECK THE POSITION AND SETROOT
		if(this.rootPage != 'login'){
			this.initLocatorInfo();
		}
		  await Promise.resolve(this.storage.set('user', this.userInfo));
	}
  
	async initCustomer()	{	
		if(this.userInfo['id']){ await this.checkSteps();		}
		this.rootPage	=	this.commons.getStatusPage({info : this.userInfo}); //SHOULD DO METHOD TO CHECK THE POSITION AND SETROOT
		if(this.rootPage != 'login'){ this.initLocatorInfo();	}
	}
  
	async initLocalStorageCustomer(){
		this.userInfo					=	await Promise.resolve(this.storage.get('user'));
		this.userInfo					=	this.userInfo || {};
		this.userInfo.control 			= 	(Object.keys(this.userInfo).length === 0 && this.userInfo.constructor === Object) ? {} : this.userInfo.control;
		this.userInfo.control.isLogged	=	this.userInfo.control.isLogged		!= undefined	?	this.userInfo.control.isLogged		: false;
		this.userInfo.control.hasCurrent=	this.userInfo.control.hasCurrent	!= undefined 	?	this.userInfo.control.hasCurrent	: false;
		this.userInfo.control.status	=	this.userInfo.control.status		!= undefined	?	this.userInfo.control.status		: 'NOT_LOGGED';			
	}

	/**
	 * Check if userInfo has been loaded and get his data from firebase. Then proceed with the inisalization of the customer methods.
	*/
	async checkSteps()		{	
		if(this.userInfo.control.hasCurrent){
			await this.tripCtrl.checkAllSteps(this.userInfo);
			await this.chat.init({ userInfo: this.userInfo });
		} else {
			await this.tripCtrl.checkIfMarkActive(this.userInfo);
		}
	}

	async initLocatorInfo()	{	
		// this.locator.getLocation();
		this.locator.startTracking();
		// Set arrival points and ser init GPS
		let points	= [
			{ lng: 2.7355689, lat: 39.5490220,	event: "_ARRIVAL_FINGER" 	},	// Finger
			{ lng: 2.7309072, lat: 39.5488731,	event: "_ARRIVAL_BELT" 		},	// Belt
			{ lng: 2.7304727, lat: 39.5477066,	event: "_ARRIVAL_MEETING" 	},	// Meeting Point
			{ lng: 2.7305210, lat: 39.5464202,	event: "_ARRIVAL_TRANSPORT" },	// Transport
			{ lng: 2.7316260, lat: 39.5299465,	event: "_ARRIVAL_LODGING" 	}	// Hotel
			]

		let pointsTest	=	await Promise.resolve(this.storage.get('testPoints')) || points;
	
		this.locator.init({userId	: this.userInfo.id, points: pointsTest });
		this.locator.setPosition({lng:0,lat:0});
	}
	
	async initMenu()		{	
		this.pages = [
			{	title		: await this.commons.getTranslate('_HOME'),	
				id			: 'home',
				icon 		: 'home',
				url			: '/main',				
				clicked 	: false, 
				subPages 	: [] 	
			},
			{	title		: await this.commons.getTranslate('_CHAT'),
				id			: 'chat',	
				icon 		: 'chatbubbles', 				
				clicked 	: false, 
				url			: '/chat-master',
				subPages 	: [] 	
			},

			{	title		: await this.commons.getTranslate('_ISSUES'),
				id			: 'issues', 		
				icon 		: 'alert-circle', 	
				_color		: 'faGreen', 
				clicked 	: false, 
				url			: '/issues',
				subPages 	: [] 	
			},
			{	title		: await this.commons.getTranslate('_PREFERENCES'),	
				id			: 'settings', 			
				icon 		: 'cog', 					
				_color		: 'faGreen', 
				clicked 	: false, 
				url			: '/settings',
				subPages 	: [] 	
			},
			{	title		: await this.commons.getTranslate('_CHECKOUT'),	
				id			: 'checkout',
				icon 		: 'log-out',	 			
				_icon 		: 'calendar-check',
				_color		: 'faGreen',
				clicked 	: false,
				url			: '/checkout',
				subPages 	: []	
			},
			{	title		: await this.commons.getTranslate('_LOG_OUT'),	
				id			: 'logout',
				icon 		: 'log-out',	 			
				_color		: 'faGreen', 
				clicked 	: false, 
				url			: '/login',
				subPages 	: []	
			},

			{
				title		: await this.commons.getTranslate('_RESTART'),
				id			: 'restart',
				_color		: 'faGreen', 
				clicked 	: false, 
				url			: '/login',
				subPages 	: []	
			}
		];
	}
						
	async openPage(page) 	{	
		switch(page.id){
			case 'null'		: 	return;

			case 'restart'	: 	if(this.userInfo.id){
									await this.tripCtrl.restartTest(this.userInfo);
									await Promise.resolve(this.storage.set('user',{}));
									this.afAuth.signOut();
									return this.navCtrl.navigateRoot('login');
								} else {
									console.log("[app] Error on restart");
									console.log(this.userInfo);
								}
								break;

			case 'logout'	:	await Promise.resolve(this.storage.set('user', {}));
								await this.afAuth.signOut();
								this.navCtrl.navigateRoot('login');
								break;
			default			:	this.navCtrl.navigateForward(page.url);
								break
		}
	}
}
