import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonsService } from '../commons/commons.service';
interface Point { lat: any, lng: any };
declare var google	:	any;

@Injectable({
  providedIn: 'root'
})
export class MapControllerService {

	public mapInstances	:	BehaviorSubject<any> = new BehaviorSubject<any>(null);
	pageInfo  : any = {
		defaultPoints	: [
		{ lng: 2.7355689, lat: 39.5490220,	event: "_ARRIVAL_FINGER" 	  },	// Finger
		{ lng: 2.7309072, lat: 39.5488731,	event: "_ARRIVAL_BELT" 		  },	// Belt
		{ lng: 2.7304727, lat: 39.5477066,	event: "_ARRIVAL_MEETING" 	},	// Meeting Point
		{ lng: 2.7305210, lat: 39.5464202,	event: "_ARRIVAL_TRANSPORT" },	// Transport
		{ lng: 2.7316260, lat: 39.5299465,	event: "_ARRIVAL_LODGING" 	}],	// Hotel
		
	iconsMarkers	:	[
		{ value		:	'supermarket',
		  img		:	'https://cdn4.iconfinder.com/data/icons/commerce-2/500/Map_marker_gps_location_map_store_1-512.png'	
		},
		{ value		:	'store',
		  img		:	'https://cdn3.iconfinder.com/data/icons/map-markers-1/512/supermarket-512.png'	
		},
		{
			value 	:	'health',
			img		:	'https://cdn3.iconfinder.com/data/icons/medical-health-1-1/761/Hospital_location-512.png'
		},
		{
			value	:	'restaurant',
			img		:	'https://cdn3.iconfinder.com/data/icons/map/500/restaurant-512.png'
		},
		{
			value	:	'party',
			img		:	'https://cdn2.iconfinder.com/data/icons/places-4/100/drink_place_marker_location_bar_coctail_party-512.png'
		},
		{
			value	:	'garden',
			img		:	'https://image.flaticon.com/icons/png/512/9/9770.png'
		}
	]
  }
  
  constructor(	private commons : CommonsService,
				private afs		: AngularFirestore) {}

  async getPointsFromAirport(code){
	 return await Promise.resolve(this.afs.collection('airports').doc(code).ref.get());
  }

  async getCoordsFlight(airport, info){
	let points		=	await this.getPointsFromAirport(airport);

	if(points.data().points){
		let infoGate	=	this.getCoordsGate(info.gate, points);
		let infoBelt	=	this.getCoordsBelt(info.belt, points);
		return {
			gate : infoGate, 
			belt : infoBelt
		}
	}else{
		return null;
	}
  }
  getCoordsGate(gate, points){
	let findedGate	= points.data().points.find(point => point.entity == 'gate' && point.name == gate);
	if(findedGate && findedGate.coords){
		return {name : gate, coords : findedGate.coords};
	}else{
		//should return a default value from the airport
		return null;
	}
  }

  getCoordsBelt(belt, points){
	let findedBelt	= points.data().points.find(point => point.entity == 'belt' && point.name == belt);
	if(findedBelt && findedBelt.coords){
		return {name : belt, coords : findedBelt.coords};
	}else{
		//should return a default value from the airport
		return null;
	}
  }

	getDefaultPoints()		{	return this.pageInfo.defaultPoints;	}
	formatIconUrl(marker)	{

		let findCategory	=	this.pageInfo.iconsMarkers.find(el => marker.types.some(type => type == el.value));
		let markerUrl		=	findCategory ? findCategory.img : marker.icon;
		return {	url 		: markerUrl, 
					scaledSize	: {	width: 32,
									height: 32
								}
				}
	}

	initMapConfig(params){
		return  {
			location	:	params['location'] 	?	params['location'] 
												:
													{
														lat : 39.546462 , 
														lng : 2.730327
													},
			zoom				:	15,
			fullscreenControl	: 	false,
			streetViewControl	: 	false,
			zoomControl			:	false,
			displayMyLodging	: 	true,
			fitBounds			:	true,
			usePanning			:	true,
		}     
	}
	
	/**
	 * Upload or add attributes to the object 
	 * 
	 * @param object Configuration of the map, must be an object
	 * @param params 	Params which one to be added or uploaded
	 */
	updateObject(object, params){	Object.keys(params).forEach(key =>{	object[key]	= params[key];	});}
	addElement(mapInstance, type, params){		if(!mapInstance){return;}
												switch(type){
													case 'marker'	:	return mapInstance.addTypeMarker(params);
													case 'polyline'	:	return mapInstance.addPolyline(params);
												}
										}
	getElement(mapInstance,type, id)	{		if(!mapInstance){return null;}
												switch(type){
													case 'marker'	:	return mapInstance.getTypeMarker(id);
												}
										}
	removeElement(mapInstance,type,id)	{		if(!mapInstance){return null;}
												switch(type){
													case 'marker'	:	return mapInstance.removeTypeMarker(id);
												}
										}
		/**
	 * 
	 * @param init object with lat lng
	 * @param destination object with lat lng
	 * @param waypoints array of object with lat lng
	 */
	calculateRoute(init, destination, waypoints?) : Promise<any>{
		var directionsService 	=	new google.maps.DirectionsService;
		return new Promise((accept,reject)=>{
			directionsService.route({
				origin				: 	init,
				destination			: 	destination,
				waypoints			: 	waypoints,
				optimizeWaypoints	: 	true,
				travelMode			:	'DRIVING'
			  }, (response, status) => {
					if (status === 'OK') {
						// directionsDisplay.setDirections(response);
						console.log(response);
						accept(response);
					} else {
						reject('Directions request failed due to ' + status)
					}
				}); 
		})
	}

	  	/**
	 * Check distance between two geopoints in meters
	 * @param me 
	 * @param p 
	 */
	checkDistance(me:Point,p:Point)
	{  
		var R 		= 6378.137; // Radius of earth in KM
		var dLat 	= p.lat * Math.PI / 180 - me.lat * Math.PI / 180;
		var dLng 	= p.lng * Math.PI / 180 - me.lng * Math.PI / 180;
		var a 		= Math.sin(dLat/2) * Math.sin(dLat/2)
					    + Math.cos(me.lat * Math.PI / 180) 
						* Math.cos(p.lat * Math.PI / 180) 
						* Math.sin(dLng/2) 
						* Math.sin(dLng/2);

		var c 		= 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
		var d 		= R * c;

		// Return value in meters
		return d * 1000;
	}
}


// INFO, must put in an external file

// Marker
	// 	id			:	'test',
	// 	icon		:	'lodging',
	// 	display		:	true,
	// 	location	:	destination

// Polyline
	// id		:	'general_route',
	// display	:	true,
	// path 	:	this.pageInfo.orderStops.path => array of lat/lng,
	// color	:	'steelblue',


