import { Injectable 		} from '@angular/core';
import { AngularFirestore	} from '@angular/fire/firestore';
import { CommonsService 	} from '../commons/commons.service';
import { FirebaseService 	} from '../firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class TripsService {

  constructor(
	private afs			   	:	AngularFirestore,
	private firebaseService	:	FirebaseService,
	private commons 		:	CommonsService,
  ) { }


  	async getCurrentTripById(user,current)	{	if(!current) return null;
												return await Promise.resolve(this.afs.collection('users').doc(user).collection('trips').doc(current).ref.get())
											}
	async getMyTrips(userId)				{	return await Promise.resolve(this.afs.firestore.collection('users').doc(userId).collection('trips').get());		}
	async addTrip(idUser,trip)				{	await Promise.resolve(this.afs.collection('users').doc(idUser).collection('trips').doc(trip.id).set(trip));		}
	async getRefTrip(idUser, idTrip)		{	return await this.afs.collection('users').doc(idUser).collection('trips').doc(idTrip).ref.get();		}
	checkIfCurrentTrip(user,trip)			{	return true;																									}
	async markTripAsCurrent(idUser,idTrip)	{	await Promise.resolve(this.afs.collection('users').doc(idUser).update({current :  idTrip}));					}


	async checkDmcBooking(dmcId, dmcDestination, voucher){
		return await Promise.resolve(this.afs.collection('dmcs').doc(String(dmcId).toString()).collection('destinations')
																.doc(String(dmcDestination).toString()).collection('bookings').doc(voucher).ref.get());
	}


	async checkAllSteps(userInfo){
		let user 			= 	await this.firebaseService.getUserById(userInfo.id);
		let currentTrip		=	await this.getCurrentTripById(userInfo.id,user.data().current);

		if(undefined==currentTrip){ return false;	}
		
		let currentTripData =	currentTrip.data();

		if(currentTripData){
			if(!currentTripData.addedToGroup){
				await this.addTripCustomerToGroup(user.data(),currentTripData)
			}else{
				console.log('ITS ON THE GROUP');
			}
			
			if(currentTripData.rooms && currentTripData.rooms.length > 0){
				console.log(currentTripData);
				let foundRep=	currentTripData.rooms.find(el => el.profile == 'rep');
				if(!foundRep){
					await this.createChatWithRep(userInfo.id, user.data().current);
				}
			}
		}
	}

	async checkIfMarkActive(user){
		let userInfo	=	await this.firebaseService.getUserById(user.id);
		let myTrips 	=	await Promise.resolve(this.afs.firestore.collection(`users`).doc(user.id).collection('trips').get());
		let tripToActivate = null;
		myTrips.forEach((doc)=>{
			if(doc.id === 'K1611267'){ //K1611267
				tripToActivate = {id : doc.id, data : doc.data()};
				console.log('HERE MARK ACTIVE');
			}
		 });
		 console.log('TRIP TO ACTIVATE', tripToActivate)
		 if(tripToActivate != null){
			user.current 			= tripToActivate.id;
			user.control.hasCurrent = true;
			await this.markTripAsCurrent(user.id,tripToActivate.id);
			await this.addTripCustomerToGroup(user,tripToActivate.data);
			await this.markTripAsCurrent(user.id,tripToActivate.id);
			await this.createChatWithRep(user.id,tripToActivate.id);
			console.log('GOING TO MARK', userInfo);
		 }
	}

	async createRoom(referenceTripTourist, referenceRep)
	{
		
		const dataRoom = await Promise.resolve( this.afs.collection('rooms')
														.add({	isGroup	:false, 
																users	:[ 
																	referenceTripTourist,
																	referenceRep
																]
														}));
		return dataRoom;
	}


	/**
	 * SHOULD REFACTOR 
	 * @param customer customer object
	 * @param trip trip object 
	 */
	async addTripCustomerToGroup(customer,trip){
		console.log('Add trip to group');
		if(!trip.group){ return;} 
		let groupInfo	=	await this.getGroupById(trip.group);
		if(groupInfo.exists){
			let usersGroup 		= groupInfo.data().users || [];
			let newUser 		= {
				avatar		: customer.avatar,
				name 		: customer.name, 
				pax 		: customer.pax || null,
				hasApp		: true,
				phone 		: customer.phone || null,
				email 		: customer.email || null,
				lodging 	: trip.lodging,
				locale 		: customer.locale,
				groupId 	: groupInfo.id,
				id 			: customer.id,
				ref 		: groupInfo.ref,
				added		: new Date()
			};
			let foundUser = usersGroup.find(user => user.id == newUser.id);
			let refTrip		=	await this.getRefTrip(customer.id,trip.id);
			if(!foundUser){
				usersGroup.push(newUser);
				const updated 	= 	await this.firebaseService.updateItemByRef(groupInfo.ref,{users : usersGroup})
				await this.firebaseService.updateItemByRef(refTrip.ref,{addedToGroup : true})
				return updated;
			}else{
				if(refTrip.data().addedToGroup == undefined || refTrip.data().addedToGroup == false){
					await this.firebaseService.updateItemByRef(refTrip.ref,{addedToGroup : true})					
				}
			}
		}else{
			console.log('THIS GROUP DONT EXIST');
		}
	}
	async getGroupById(id)	{		return await Promise.resolve(this.afs.collection('groups').doc(id).ref.get());	}
	async getRepFromGroup(groupId){
		console.log('GETREP GROUP', groupId)
		let repRef	=	await Promise.resolve(this.afs.collection('groups').doc(groupId).ref.get());
		console.log('the rep from the group', repRef);
		return repRef.data().refRep || '/users/3';
	}	
	/**
	 * SHOULD REFACTOR
	 * @param idUser 
	 * @param idTrip 
	 */
	async createChatWithRep(idUser,idTrip){

		try {
			let infoTripUser	=	await this.getCurrentTripById(idUser,idTrip);
			let infoUser		=	await this.firebaseService.getUserById(idUser);
			// let repReference 	= 	'/users/3';
			let repReference 	= 	infoTripUser.data().addedToGroup ? await this.getRepFromGroup(infoTripUser.data().group) : '/users/3';
			let rep  			= 	await this.firebaseService.getItemByRef(repReference);

			let tripRooms		=	infoTripUser.data().rooms || []; //Get the rooms from the current trip
			let foundRoom 		= 	tripRooms.findIndex(room => room.repReference.toString() == rep.ref.toString()); //Find if already exists a room with the rep

			console.log(infoTripUser.data());
			console.log(repReference);
			console.log('ROOMS FROM USER', tripRooms);
			
			if (foundRoom === -1){
				let dataNewRoom =	await this.createRoom(infoTripUser.ref,rep.ref); //Create the new room between currentTrip and REP
				console.log('dataRoom', dataNewRoom);
				// Add new entrance to Trip Rooms from current trip
				let repInfo = rep.data();

				if(repInfo){	let newRoomTrip =	{
									avatar  	: rep.data().avatar,
									name 		: rep.data().name,
									profile 	: 'rep',
									repReference: rep.ref,
									reference	: dataNewRoom,
									id			: dataNewRoom.id
								}
								tripRooms.push(newRoomTrip);
								await this.firebaseService.updateItemByRef(infoTripUser.ref, {rooms : tripRooms});
								// Add new entrance to Rep Rooms from current trip
								let newRoomRep = {
									avatar            		: infoUser.data().avatar || null,
									name              		: infoUser.data().name	 || '_unknown_name',
									profile           		:'customer',
									room              		: Math.floor(Math.random() * 500),
									group             		: infoTripUser.data().group || 'NO GROUP',
									hasApp            		: true,
									pax               		: infoTripUser.data().pax || 2,
									currentTripReference 	: infoUser.ref,
									// customerReference : infoUser.ref,
									reference         		: dataNewRoom,
									id						: dataNewRoom.id
								}
								let repRooms = rep.data().rooms || [];
								repRooms.push(newRoomRep);
								await this.firebaseService.updateItemByRef(rep.ref.path,{rooms : repRooms});
							} 
				else 		{	console.log("[tripService:createChatWithRep] No rep data found");	}
			}
			else	{	console.log('[tripService:createChatWithRep] Room is already created with this trip and rep');	}
		} catch(e){
			console.log("[tripService:createChatWithRep] Error",e)
		}
	}

	async createTripWithNoVoucher(idUser){
		let tripCreated	=	await Promise.resolve(this.afs.collection('users').doc(idUser).collection('trips').add({}));
		let refTrip		=	await Promise.resolve(tripCreated.get());
		return { id :	tripCreated.id,
				ref :	refTrip.ref}
	}

	async checkIfBookingAlreayAdded(voucher){
		console.log(voucher);
		return Promise.resolve(this.afs.collection('grouping').doc('bookings').collection('checkins').doc(voucher).ref.get());
	}

	async checkIfBookingExist(voucher){
		return Promise.resolve(this.afs.collection('bookings').doc(voucher).ref.get());
	}
	/**
	 * Method which create a room chat to the customers from the current voucher. Actually is not used. 06/08/2019. 
	 * @param user user which put the vouhcer code
	 * @param booking room from the booking using as id
	 */
	async addBookingChecked(user, booking){
		let userRef		= await this.firebaseService.getUserById(user.id);
		let infoRoom	= {
			name	:	'family',
			chatRoom: 	null,
			items	: 	[{
				ref		:	userRef.ref,
				isOwner	:	true,
				role	:	'_ADMIN',
				privs	:	'_ALL'
			}]
		};
		return Promise.resolve(this.afs.collection('grouping').doc('bookings').collection('checkins').doc(booking).set(infoRoom));
	}


	async restartTest(user){
		let userToReset	=	await this.firebaseService.getUserById(user.id);
		let infoDoc		=	await this.afs.collection('users').doc(user.id).collection('trips').doc('K1611267').ref.get();
		
		if(!infoDoc.exists){ this.commons.translateDebug('TEST USER RESTARTED'); return;}
		
		await this.afs.collection('users').doc(user.id).collection('trips').doc('K1611267').delete();
		let userUpdated	=	userToReset.data();
		userUpdated.control	=	{
			hasCurrent		:	false,
			isLogged		:	false,
			status			:	'INIT',
			statusProcess	:	'INIT'
		};
		userUpdated.current		=	null;
		userUpdated.listTrips	=	[];
		await this.afs.collection('users').doc(user.id).update(userUpdated);
		await this.afs.collection('grouping').doc('bookings').collection('checkins').doc('K1611267').delete();
		await this.afs.collection('dmcs').doc('1').collection('destinations').doc('1').collection('bookings').doc('K1611267').update({customer_status : null});
		this.commons.translateDebug('TEST USER RESTARTED');
	}
	
	// WHAT TO DO WITH THESE METHODS

	//need to define the process to assign a trip to a group
	async assignTripToGroup( user, current)	{	return null;	}

	
}
