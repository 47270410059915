import { NgModule 									} from '@angular/core';
import { BrowserModule 								} from '@angular/platform-browser';
import { RouteReuseStrategy 						} from '@angular/router';

import { IonicModule, IonicRouteStrategy 			} from '@ionic/angular';

import { AppComponent 								} from './app.component';
import { AppRoutingModule 							} from './app-routing.module';
import { TranslateModule, TranslateLoader 			} from '@ngx-translate/core';
import { TranslateHttpLoader 						} from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient 				} from '@angular/common/http';
import { AngularFireModule 							} from '@angular/fire';
import { AngularFirestoreModule 					} from '@angular/fire/firestore';
import { AngularFireAuthModule 						} from '@angular/fire/auth';
import { IonicStorageModule 						} from '@ionic/storage';
import { NgxQRCodeModule 							} from 'ngx-qrcode2';
import { environment 								} from 'src/environments/environment';

import { SplashScreen 								} from '@ionic-native/splash-screen/ngx';
import { StatusBar 									} from '@ionic-native/status-bar/ngx';
import { Camera, CameraOptions 						} from '@ionic-native/camera/ngx';
import { Vibration 									} from '@ionic-native/vibration/ngx';
import { Geolocation 								} from '@ionic-native/geolocation/ngx';
import { ScreenOrientation 							} from '@ionic-native/screen-orientation/ngx';
import { Diagnostic                     			} from '@ionic-native/diagnostic/ngx';
import { FileOpener 								} from '@ionic-native/file-opener/ngx';
import { File 										} from '@ionic-native/file/ngx';
import { Stripe 									} from '@ionic-native/stripe/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
	BrowserModule,
	AngularFireModule.initializeApp(environment.firebase),
	AngularFirestoreModule,
    AngularFireAuthModule,
    IonicModule.forRoot(),
    AppRoutingModule,
	HttpClientModule, 
	NgxQRCodeModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({ 
      loader: {  
        provide: TranslateLoader, 
        useFactory: (createTranslateLoader),  
        deps: [HttpClient] 
      } 
    }) 
  ],
  providers: [
    StatusBar,
    SplashScreen,
	{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	Camera,
	Vibration,
	Geolocation,
	ScreenOrientation,
	Diagnostic,
  	FileOpener,
  	Stripe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
