import { FirebaseService } from './../firebase/firebase.service';
import { CommonsService } from './../commons/commons.service';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

	info		: any 	= {};
	subscriptors: any 	= { rooms: {}, messages: {} };
	callbacks	: any 	= {};
	rooms		: any[] = [];
	badges		: any	= {};
	messages	: any 	= {};
	values		: any	= {};	
	currentRoom : string;

	constructor(
		private afs   			: AngularFirestore,
		private commons			: CommonsService,
		private firebaseService	: FirebaseService,
		// private afsto		: AngularFireStorage,

  	){}


	async init(info)
	{
		//console.group("[Chat] Init");
		//console.log("Info",info);

		this.info.user 		= info.userInfo;
		
		await this.subscribeTripUser({ id : this.info.user.id });
		this.setController("user", data => {					// Set User Controller
	
			//console.group("[Chat] UserController");
			//console.log("DATA",data);

			this.unsubscribe("all_rooms");						// Remove previous rooms subscriptions
			this.rooms 		= [];								// Clear room entries
			this.messages	= [];								// Clear messages
			this.badges		= {};								// Clear badges
			
			data			= data || {};
			data.rooms 		= data.rooms ? data.rooms : [];
			
			data.rooms.forEach( roomInfo => 
			{	
				//console.log("ROOMINFO",roomInfo);
				// Subscribe to each ROOM and their messages
				this.subscribeRoom(roomInfo.reference);
				
				//Generate Rooms info
				//var roomRef = ( async () => await roomInfo.reference.get() )();
				this.rooms.push({
					id			: roomInfo.reference.id	|| null,
					name		: roomInfo.name			|| null,
					group		: roomInfo.group		|| null,
					adults		: roomInfo.adults		|| null,
					children	: roomInfo.children		|| null,
					avatar		: roomInfo.avatar		|| null,
					hasApp		: roomInfo.hasApp		|| null,
					lodging		: roomInfo.lodging		|| null,
					nationality	: roomInfo.nationality	|| null,
					qty			: roomInfo.qty			|| null,
					profile		: roomInfo.profile		|| null,
					room		: roomInfo.room			|| null
				});					 		
			});

			//console.groupEnd();
		});

		this.setController("room", data => {					// Set Room Controller
			//console.group("[Chat] RoomController");
			//console.groupEnd();
		});
				
		this.setController("messages", (data,roomId) => {		// Set Message Controller
			//console.group("[Chat] MessagesController");
			
			this.messages[roomId] = [];
			
			data.docs.forEach( info => {
				this.messages[roomId].push({
					id			: info.data().id,
					refId		: info.ref.id,
					timestamp	: info.data().timestamp,
					type		: info.data().type, 
					content		: info.data().content,
					side		: info.data().id == this.info.user.id ? 'right' : 'left',
					receivers	: info.data().receivers,
					viewers		: info.data().viewers
				});
			});

			// Current Room Messages
			if(!this.isCurrentRoom(roomId)) 
			{				
				// Get all user pending messages
				this.badges[roomId] = data.docs.filter(item => {
					if ( item.data().id==this.info.user.id 	){ return false; }
					if ( item.data().viewers && 
						 item.data().viewers.some( child => child == this.info.user.id )){	
							 return false; 
					}
					return item;
				}).length;	// Get result length
			} 
			else {
				// Update viewers in firebase
				data.docs.map( item => {
					if ( item.data().id == this.info.user.id ){ return false; }
					if ( !item.data().viewers.some( child => child == this.info.user.id )){
						//console.log("New viewer for item: ",item.data().content);
						item.ref.update( { "viewers" : item.data().viewers.concat(this.info.user.id)});
					}
				});
				
				// Get messages to show
				this.badges[this.getCurrentRoom()] = 0;
				this.callDetailController();			
			}

			// Get all user pending messages
			this.badges[roomId] = data.docs.filter(item => {
				if ( item.data().id == this.info.user.id ){ return false; }			// Avoid my messages
				if ( item.data().viewers && 
					 item.data().viewers.some( item => item == this.info.user.id )){	// Avoid viewed messages 
						 return false; 
				}
				return item;
			}).length;	// Get result length

			// Update Room Badges
			this.rooms.map( item => {
				if ( item.id == roomId ){
					item.badge = this.badges[roomId];
				}				
			});

			// Create global badge
			this.generateBadge(this.badges);			

			//console.groupEnd();
		});

		//console.groupEnd();
	}

	public setValue(name:string, values:any){		this.values[name] = values	}
	public getValue(name:string)			{		return this.values[name];	}
	public generateBadge(badges)			{									}

	/**
	 * Set callback controller
	 * @param type
	 * @param callback 
	 */
	public setController(type,callback)	{	this.callbacks[type] = callback;		}
	public getController(type)			{	return this.callbacks[type];			}
	public isCurrentRoom(room)			{	return this.info.currentRoom == room;	}
	public getCurrentRoom()				{	return this.info.currentRoom;			}
	public enterRoom(room)				{	this.info.currentRoom = room;			}
	public leaveRoom()					{	this.info.currentRoom = undefined;		}
	public updateViewers()				{	this.messages[this.info.currentRoom].forEach( item => {
											if ( item.id == this.info.user.id ){ return false; }
											if ( !item.viewers.some( child => child == this.info.user.id )){
												//console.log("New viewer for message ",item.content);
												this.afs.collection("rooms")
														.doc(this.info.currentRoom)
														.collection("messages")
														.doc(item.refId)
														.update({ "viewers" : item.viewers.concat(this.info.user.id)});
											}
										});
										}

	public callDetailController(data?)	{	if(this.callbacks["detail"] instanceof Function ){
												this.callbacks["detail"](data);
											}
										}

	/**
	 * Subscribe user doc
	 * @param info 
	 */
	public subscribeUser(info)			{	this.subscriptors.user = 	this.afs.collection('users')
																		.doc(isNaN(info.id)?info.id:info.id.toString())
																		.valueChanges()
																		.subscribe( data => this.callbacks.user(data) );
										}

	//1..N
	async subscribeTripUser(info)		{	let infoUser	=	await this.firebaseService.getUserById(info.id);
											if(!infoUser.data().current){ return; }									
											this.subscriptors.user = this.afs.collection('users')
																			.doc(isNaN(info.id)?info.id:info.id.toString())
																			.collection('trips')
																			.doc(infoUser.data().current)
																			.valueChanges()
																			.subscribe(data => this.callbacks.user(data));

	}

	
	createRoom_(referenceTourist, referenceRep)		{	return this.afs.collection('rooms').add({isGroup:false, users:[referenceTourist,referenceRep]})	}
	async createRoom(referenceTourist, referenceRep){	const dataRoom = await Promise.resolve(this.afs.collection('rooms').add({isGroup:false, users:[referenceTourist,referenceRep]}));
														return dataRoom;
													}

	async getGroupById(id)							{	const groupInfo = await Promise.resolve(this.afs.collection('groups').doc(id).ref.get())
														return groupInfo;
													}

	async addCustomerToGroup(customer)				{	let groupInfo 		= await this.getGroupById(customer.data().group);
														if(!groupInfo.exists){ return;}												
														let usersGroup 		= groupInfo.data().users || [];
														let newUser 		= {
															avatar		: customer.data().avatar,
															name 		: customer.data().name, 
															pax 		: customer.data().pax,
															hasApp		: true,
															lodging 	: customer.data().lodging,
															locale 		: customer.data().locale,
															id 			: customer.data().id,
															ref 		: customer.ref
														};
														let findedUser = usersGroup.find(user => user.id == newUser.id);
														if(!findedUser){
															usersGroup.push(newUser);
															const updated = await this.firebaseService.updateItemByRef(groupInfo.ref.path,{customers : usersGroup})
															const customerAssigned = await this.firebaseService.updateItemByRef(customer.ref.path,{addedToGroup : true})
															return updated;
														}

	}
	
	/**
	 * Subscribe to concrete room
	 */
	public subscribeRoom(roomRef:firebase.firestore.DocumentReference){
		//console.group("subscribeRoom");
		//console.log(roomRef);
		this.subscriptors.rooms[roomRef.id] = roomRef.onSnapshot(data => this.callbacks.room(data));
		this.subscribeRoomMessages(roomRef);
		//console.groupEnd();
	}

	/**
	 * Subscribe to room messages
	 * @param callback 
	 */
	public subscribeRoomMessages(roomRef:firebase.firestore.DocumentReference, info={})
	{
	

		this.subscriptors.messages[roomRef.id] = roomRef.collection("messages")
														  .orderBy("timestamp")
														  .onSnapshot(data => { this.callbacks.messages(data,roomRef.id) });

		//console.groupEnd();
	}

	/**
	 * Unsubscribe streams
	 */
	public unsubscribeAll(){
		this.unsubscribe('all_rooms');
		this.unsubscribe('user');
	}

	public unsubscribe(type,key?){
		switch(type)
		{
			case 'room'		: this.subscriptors.rooms[key](); break;
			case 'all_rooms':
				if(undefined!==this.subscriptors.rooms){
					//console.log("Unsubscribing rooms");
					Object.keys(this.subscriptors.rooms).forEach(key => {
						//console.log("Room ",key);
						// Call with empty value to unsubscribe onSnapshot
						this.subscriptors.messages[key]();	// Room messages
						this.subscriptors.rooms[key]();		// Room itself
					});
				}
				break;
			case 'user'		:				
				if(undefined!==this.subscriptors.user){
					//console.log("Unsubscribing user");
					this.subscriptors.user.unsubscribe();
				}
				break;
			default			: //console.log("Unknown type of subscription <"+type+">");
		}
	}

	// private uploadStorage(info) : AngularFireUploadTask {
	// 	return this.afsto.ref('files/'+info.name+'.'+info.extension).putString(info.content,'base64');
  	// }
  
	private async getRoomData(){
		const roomInfo = await Promise.resolve(this.afs.doc('rooms/'+this.getCurrentRoom()).ref.get());
		return roomInfo;
	}

  	public async sendMessage(info:any):Promise<any>{
		if (undefined === info 		   	|| 
			undefined === info.room 	|| 
			undefined === info.message 	|| 
			info.message.content == "" 
		){
			return Promise.reject('Empty message:'+JSON.stringify(info));
		}

		//console.group("sendMessage");
		//console.log(info);
		
		const roomInfo 		= await Promise.resolve(this.afs.doc('rooms/'+info.room).ref.get());
		const pushMessage 	= {
			sender	: {
				id		: this.info.user.id,
				name	: this.info.user.info.name
			},
			message	: {
				type	: info.message.type,
				content	: ( info => {
					switch(info.type){
						case 'text':
							return info.content;
						case 'image':
							return 'Image';
					}
				})(info.message) 
			},
			tokens	: 	( await Promise.all( 
							//this.getRoomData().data().users
							roomInfo.data().users
							.map	( async (user) => { 
								return {
									user	: user.id, 
									token 	: (await Promise.resolve(user.get())).data().token 
								}
							})
						))
						.filter( item => item["user"] != info.message.id )
						.map( item => item["token"] )
		};
		//ASK CARLOS
		pushMessage.tokens = pushMessage.tokens.filter(el => el != undefined); //<- THIS
		//console.log("PUSHMESSAGE",pushMessage);
		
		// Add message to messages collection
		//console.log("Message info",info);
		Promise.resolve(this.afs.collection('rooms').doc(info.room).collection("messages").add(info.message));
		//console.log("Pushes",pushMessage);
		Promise.resolve(this.afs.collection('pushes').add(pushMessage));
		
		//console.groupEnd();

		return Promise.resolve();
	}

	public async takePicture(type){
		//console.group("takePicture",type);

		if(this.commons.isCordova()){
			this.commons.takePicture(type,{},data => {
				this.persistPicture(data);
			});				
		} else {		
			this.persistPicture(this.commons.getFakeImage());
		}

		//console.groupEnd();
	}

	private persistPicture(data)
	{
		//console.group("Persist Picture");

		// this.uploadStorage({
		// 	name		: new Date().getTime(),
		// 	type		: 'image',
		// 	extension	: 'jpg',
		// 	content		: data
		// })
		// .then(snap=>{
		// 	snap.ref.getDownloadURL().then(url=>{
		// 		this.sendMessage({
		// 			room	: this.getCurrentRoom(),
		// 			message : {
		// 				timestamp 	: Date.now(),
		// 				id      	: this.info.user.id,
		// 				user		: this.info.user.info.name,		
		// 				type		: 'image',
		// 				content		: url,
		// 				receivers	: [],
		// 				viewers		: []
		// 			}
		// 		}).then( res => {
		// 			//console.log("Message send");
		// 		});
		// 	});
		// });
	}

	
}
