import { MapControllerService } from './../map-controller/map-controller.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Geolocation } from '@ionic-native/geolocation/ngx';

@Injectable({
  providedIn: 'root'
})
export class LocatorService {

	public myLocation	:	BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public info			: 	any = 	{};
	pageInfo        	: 	any = 	{	stepsCheckeds : []	};
	public test 		: 	any = 	[];
	options 					=	{ maximumAge: 3000, timeout: 30000, enableHighAccuracy: true };
	watch				:	any;
	
	constructor(
		private geolocation 	: Geolocation,
		private mapCtrl			: MapControllerService
	) { }

	init(info:any)	{		this.pageInfo.userId	= info.userId;
							this.info.threshold		= 15;
							this.info.simulate		= true;
							this.info.timeout		= 5000;
							this.info.points 		= info.points ;
							this.info.me			= { lat: 0, lng: 0};
							this.runSimulation();
					}

	getLocation()	{
		return new Promise((resolve, reject) =>{
			this.geolocation.getCurrentPosition()
			.then((resp : any) => {
				resolve(resp);
				this.setPosition({lat : resp.coords.latitude, lng : resp.coords.longitude});
			}).catch((error) => {
				// alert('error' + JSON.stringify(error));
					console.log('Error getting location', error);
					reject(error);

				});
		})
	}

	async forceGetLocation(){
		let location	=	 await this.geolocation.getCurrentPosition();
		this.setPosition({lat : location.coords.latitude, lng : location.coords.longitude});
	}
	startTracking(){

		this.watch = this.geolocation.watchPosition(this.options);

		this.watch.subscribe((data) => {
			//    console.log('on watch', data);
			//    alert(data.coords.latitude+','+data.coords.longitude)
			if(data.coords){
				this.setPosition({lat : data.coords.latitude, lng : data.coords.longitude});
			}
		   });	   
	}

	setPosition(position:{lat:any,lng:any}){
		this.info.me = position;
		this.myLocation.next(this.info.me);
	}

	/**
	 * Run Simulation until someone stop it
	 */
	runSimulation(){
		// this.commons.debug("Locator runSimulation");
		this.checkPosition();
		if(this.info.simulate){
			setTimeout(()=>{ this.runSimulation() },this.info.timeout);
		}
	}


	getPosition(){
		return this.info.me;
	}
	checkPosition(){
		// this.commons.debug("Locator Check position");
		//console.log("Check Position");
		// console.log('%c a colorful message','background: green; color: white; display: block;');
		// console.log('INFO POINTS ARE', this.info.points);

		this.info.points.forEach((point,i) => {
			let distance = this.mapCtrl.checkDistance(this.getPosition(),point);
			// console.log("[Me]",this.getPosition(),". Point",point,"Distance",distance);
			this.test.push({distance: distance.toFixed(2),threshold: this.info.threshold,event: point.event});
			if(distance<=this.info.threshold){
				// this.publish(point.event);
				if(this.pageInfo.stepsCheckeds.indexOf(point.event) === -1){
					this.pageInfo.stepsCheckeds.push(point.event);
					this.arrivalAssignPage(point.event);
				}
			}

		});
	}
	async arrivalAssignPage(event){
		// let nav	=	this.app.getActiveNav();
		// // alert('Locator Push ' + event);
		// switch(event){
		// 	case '_ARRIVAL_FINGER'		: 	await this.firebaseUsers.updateStatusTestUser(this.pageInfo.userId,'_ARRIVAL_FINGER'); 
		// 									await this.firebaseUsers.updateBookingStatus('_ARRIVAL_FINGER');
		// 									nav.push('FingerPage');
		// 									break;
		// 	case '_ARRIVAL_BELT'		:	await this.firebaseUsers.updateStatusTestUser(this.pageInfo.userId,'_ARRIVAL_BELT');
		// 									await this.firebaseUsers.updateBookingStatus('_ARRIVAL_BELT');
		// 									nav.push('BeltPage');
		// 									break;
		// 	case '_ARRIVAL_MEETING'		:	await this.firebaseUsers.updateStatusTestUser(this.pageInfo.userId,'_ARRIVAL_MEETING');
		// 									await this.firebaseUsers.updateBookingStatus('_ARRIVAL_MEETING');
		// 									nav.push('MeetingpointPage');
		// 									break;
		// 	case '_WAITING_POINT_GROUP'	:	await this.firebaseUsers.updateStatusTestUser(this.pageInfo.userId,'_WAITING_POINT_GROUP');
		// 									await this.firebaseUsers.updateBookingStatus('	');
		// 									nav.push('MeetingpointPage');
		// 									break;
		// 	case '_ARRIVAL_TRANSPORT'	:	await this.firebaseUsers.updateStatusTestUser(this.pageInfo.userId,'arrivalPendingToBoard');
		// 									// await this.firebaseUsers.updateStatusTestUser(this.pageInfo.userId,'_ARRIVAL_TRANSPORT');
		// 									await this.firebaseUsers.updateBookingStatus('arrivalPendingToBoard');
		// 									nav.push('TransportPage');
		// 									break;
		// 	case '_ARRIVAL_LODGING'		:	await this.firebaseUsers.updateStatusTestUser(this.pageInfo.userId,'_ARRIVAL_LODGING');
		// 									await this.firebaseUsers.updateBookingStatus("_ARRIVAL_LODGING");								
		// 									nav.push('MainPage');
		// 									break;
		// }
	}
	
	async addSkippedStep(userId, event){
		// this.pageInfo.stepsCheckeds.push(event);
		// console.log(this.pageInfo.stepsCheckeds);
		// await this.firebaseUsers.updateBookingStatus(event);	
		// await this.firebaseUsers.updateStatusTestUser(userId,event);
	}
}
